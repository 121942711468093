import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js/auto'


import { AppService } from '@services/app.service';
import { CriptografiaService } from '@services/criptografia.service';
@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent implements OnInit {
  public chart: any;
  private token: string;
  private idUsuario: string;


  corCard = ["bg-yellow", "bg-green", "bg-red"];
  icones = ["ion-ios-infinite", "ion-android-checkbox", "ion-android-cancel"]
  dataNFParana: any[] = [];

  constructor(
    private app: AppService,) {


    this.app.getToken();
    this.app.getIdUsuario();
    this.token = this.app.token;
    this.idUsuario = this.app.idUsuario;

  }

  

  ngOnInit(): void {
    //this.createChart();
    // this.lineChart();
    
  }

  
}
