import {
    Component,
    HostBinding,
    OnDestroy,
    OnInit,
    Renderer2
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import * as CryptoJS from 'crypto-js';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from '@services/usuario.service';
@Component({
    selector: 'app-recover-password',
    templateUrl: './recover-password.component.html',
    styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';

    public recoverPasswordForm: UntypedFormGroup;
    public isAuthLoading = false;
    private key = CryptoJS.enc.Utf8.parse("46pzR1D9rnyyX6ifJhvAnIANYIeHsfWf");
    private iv = CryptoJS.enc.Utf8.parse("1tRLHFObk5orY9GD");
    private token;
    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private _Activatedroute: ActivatedRoute,
        private api: UsuarioService,
        private router: Router,
    ) { }


    encryptUsingAES256(text): any {
        var encrypted = CryptoJS.AES.encrypt(text, this.key,
            {
                mode: CryptoJS.mode.CBC,
                iv: this.iv
            });
        return encrypted.toString();
    }

    ngOnInit(): void {
        this.token = this._Activatedroute.snapshot.paramMap.get("token");
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
        this.recoverPasswordForm = new UntypedFormGroup({
            password: new UntypedFormControl(null, Validators.required),
            confirmPassword: new UntypedFormControl(null, Validators.required)
        });
    }

    recoverPassword() {
        if (this.recoverPasswordForm.valid) {
            var senha1 = this.recoverPasswordForm.value.password;
            var senha2 = this.recoverPasswordForm.value.confirmPassword
            if (senha1 != senha2) {
                this.toastr.error('As senhas não coincide');
            }

            else {
                var hash = CryptoJS.SHA256(this.recoverPasswordForm.value.password).toString(CryptoJS.enc.Hex);
                const payload = {
                    senha: this.encryptUsingAES256(hash),
                    token: this.encryptUsingAES256(this.token)
                }
                this.api.alterarSenha(payload)
                    .subscribe(res=>{
                        if (res.status === 'Sucesso') {
                            this.toastr.success(res.msg);
                            this.router.navigateByUrl('/login');
                        }
                        else{
                            this.toastr.error(res.msg);
                        }
                    })
                
            }
        } else {
            this.toastr.error('Informe as senhas');
        }
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}
