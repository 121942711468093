import { AppState } from '@/store/state';
import { UiState } from '@/store/ui/state';
import { Component, HostBinding, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppService } from '@services/app.service';
import { Observable } from 'rxjs';
import { TelaService } from '@services/tela.service';
import { ToastrService } from 'ngx-toastr';
import { CriptografiaService } from '@services/criptografia.service';
const BASE_CLASSES = 'main-sidebar elevation-4';
import * as CryptoJS from 'crypto-js';
@Component({
    selector: 'app-menu-sidebar',
    templateUrl: './menu-sidebar.component.html',
    styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit {
    @HostBinding('class') classes: string = BASE_CLASSES;
    public ui: Observable<UiState>;
    public user;
    public menu: any = [];
    public perfil;
    public idUsuario;
    public token;

    constructor(
        public appService: AppService,
        private store: Store<AppState>,
        private api: TelaService,
        private toastr: ToastrService,
        private apiCripto: CriptografiaService
    ) { }

    ngOnInit() {
        this.ui = this.store.select('ui');
        this.ui.subscribe((state: UiState) => {
            this.classes = `${BASE_CLASSES} ${state.sidebarSkin}`;
        });
        this.user = this.appService.user;
        this.appService.getPerfil();
        this.appService.getIdUsuario();
        this.appService.getToken();
        this.idUsuario = this.appService.idUsuario;
        this.token = this.appService.token;
        this.perfil = this.appService.perfil;
        console.log(this.perfil);

        this.generateMenu();
    }

    generateMenu() {

        const payload = {
            idUsuario: this.idUsuario,
            token: this.token
        }
        this.api.getPerfilTela(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
            .subscribe(res => {
                if (res.status === 'Sucesso') {
                    var temp = res.data;

                    this.apiCripto.buscaChaveUser(encodeURIComponent(this.idUsuario), encodeURIComponent(this.token))
                        .subscribe(e => {
                            var tempKey = e.data;
                            var keyPadrao = this.apiCripto.getKeyPadrao();
                            var keyUser = CryptoJS.enc.Utf8.parse(this.apiCripto.decryptUsingAES256(tempKey, keyPadrao));

                            temp.forEach(e => {
                                var mostra_menu = this.apiCripto.decryptUsingAES256(e.mostra_menu, keyUser);
                                var filhos = e.filhos;


                                if (mostra_menu == 1) {

                                    if (filhos != undefined && filhos.length > 0) {
                                        //console.log('entrou')
                                        //console.log(this.apiCripto.decryptUsingAES256(e.nm_tela, keyUser))
                                        //console.log(filhos.length)
                                        var childrens = [];

                                        filhos.forEach(c => {

                                            var mostra = this.apiCripto.decryptUsingAES256(c.mostra_menu, keyUser);
                                            var mostra_filho_menu = false;
                                            if (mostra == 1){
                                                mostra_filho_menu = true;
                                            }
                                            const temp1 = {
                                                name: this.apiCripto.decryptUsingAES256(c.nm_tela, keyUser),
                                                path: this.apiCripto.decryptUsingAES256(c.url, keyUser),
                                                iconClasses: this.apiCripto.decryptUsingAES256(c.icone, keyUser),
                                                show: mostra_filho_menu
                                            };
                                            childrens.push(temp1);
                                            //console.log(temp1);
                                        });

                                        var mostra = this.apiCripto.decryptUsingAES256(e.mostra_menu, keyUser);
                                        var mostra_filho_menu = false;
                                        if (mostra == 1){
                                            mostra_filho_menu = true;
                                        }
                                        const temp1 = {
                                            name: this.apiCripto.decryptUsingAES256(e.nm_tela, keyUser),
                                            iconClasses: this.apiCripto.decryptUsingAES256(e.icone, keyUser),
                                            path: [this.apiCripto.decryptUsingAES256(e.url, keyUser)],
                                            show: mostra_filho_menu,
                                            children: childrens
                                        };
                                        this.menu.push(temp1);

                                    }
                                    else {

                                        var mostra = this.apiCripto.decryptUsingAES256(e.mostra_menu, keyUser);
                                        var mostra_filho_menu = false;
                                        if (mostra == 1){
                                            mostra_filho_menu = true;
                                        }
                                        const temp1 = {
                                            name: this.apiCripto.decryptUsingAES256(e.nm_tela, keyUser),
                                            iconClasses: this.apiCripto.decryptUsingAES256(e.icone, keyUser),
                                            path: [this.apiCripto.decryptUsingAES256(e.url, keyUser)],
                                            show: mostra_filho_menu
                                            
                                        };
                                        this.menu.push(temp1);

                                    }


                                }
                            });
                        });
                }
                else {
                    this.toastr.error(res.msg);
                }
            });

        /*
        if (this.perfil === 'Administrador') {
            const temp1 = {
                name: 'Usuário',
                iconClasses: 'fas fa-user',
                path: ['/lst-usuario'],
                show: false
            };

            this.menu.push(temp1);

            const temp2 = {
                name: 'Perfil',
                iconClasses: 'fas fa-user-circle',
                path: ['/lst-perfil'],
                perfil: 'Administrador',
                show: false
            }

            this.menu.push(temp2);
        }

      */
        /*
                const temp5 = {
                    name: 'Principal',
                    iconClasses: 'fab fa-sistrix',
                    path: ['/principal'],
                    show: true
                };
                this.menu.push(temp5);
        
        
        
                const temp4 = {
                    name: 'Acesso',
                    iconClasses: 'fas fa-folder',
                    children: [
                        {
                            name: 'Tela',
                            iconClasses: 'fas fa-file',
                            path: ['/lst-tela'],
                        },
                        {
                            name: 'Perfil',
                            iconClasses: 'far fa-address-book',
                            path: ['/lst-perfil'],
                        },
                        
                    ]
                }
        
               
        
                this.menu.push(temp4);
              */



        /*
               
                const temp2 = {
                    name: 'Custo',
                    iconClasses: 'fas fa-folder',
                    children: [
                        {
                            name: 'Conta',
                            iconClasses: 'fas fa-file',
                            path: ['/custo/lst-conta']
                        },
                        {
                            name: 'Categoria',
                            iconClasses: 'far fa-address-book',
                            path: ['/custo/lst-categoria']
                        },
                        {
                            name: 'Lancamento',
                            iconClasses: 'fas fa-file',
                            path: ['/custo/lst-lancamento']
                        },
                        {
                            name: 'NF Link',
                            iconClasses: 'fas fa-file',
                            path: ['/custo/nf-link']
                        },
                        {
                            name: 'NF Parana',
                            iconClasses: 'fas fa-file',
                            path: ['/custo/nf-parana']
                        },
        
                        {
                            name: 'Cartões',
                            iconClasses: 'fas fa-file',
                            path: ['/custo/lst-cartao']
                        },
                        {
                            name: 'Transferência',
                            iconClasses: 'fas fa-file',
                            path: ['/custo/lst-transferencia']
                        },
                    ]
                }
                this.menu.push(temp2);
        */
        /*
                const tempXXX = {
                    name: 'XXX',
                    iconClasses: 'fas fa-folder',
                    children: [
                        {
                            name: 'Atriz',
                            iconClasses: 'fas fa-file',
                            path: ['/xxx/xxx-atriz']
                        },
                        {
                            name: 'Melhores Vídeos',
                            iconClasses: 'far fa-address-book',
                            path: ['/xxx/xxx-melhores-videos']
                        },
                        {
                            name: 'Ultimos Vídeos',
                            iconClasses: 'fas fa-file',
                            path: ['/xxx/xxx-ultimos-videos']
                        },
                        {
                            name: 'Video Aleatório',
                            iconClasses: 'fas fa-file',
                            path: ['/xxx/day-video']
                        },
        
        
        
                    ]
                }
                this.menu.push(tempXXX);
                */
    }
}

/*export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/']
    },
    {
        name: 'Blank',
        iconClasses: 'fas fa-file',
        path: ['/blank']
    },
    {
        name: 'Main Menu',
        iconClasses: 'fas fa-folder',
        children: [
            {
                name: 'Sub Menu',
                iconClasses: 'far fa-address-book',
                path: ['/sub-menu-1']
            },
            {
                name: 'Blank',
                iconClasses: 'fas fa-file',
                path: ['/sub-menu-2']
            }
        ]
    }
];
*/

/*
export const MENU = [
    {
        name: 'Dashboard',
        iconClasses: 'fas fa-tachometer-alt',
        path: ['/'],
        show:false
    },
    {
        name: 'Blank',
        iconClasses: 'fas fa-file',
        path: ['/blank'],
        show:false
    },
  
    {
        name: 'Usuário',
        iconClasses: 'fas fa-user',
        path: ['/lst-usuario'],        
        show:false
    },
    {
        name: 'Perfil',
        iconClasses: 'fas fa-user-circle',
        path: ['/lst-perfil'],
        perfil: 'Administrador',
        show:false
    },
    {
        name: 'Consulta NF',
        iconClasses: 'fab fa-sistrix',
        path: ['/consulta-nf'],
        show:false
    },
    
];
*/
