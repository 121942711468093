<div class="float-right d-none d-sm-block">
    <b>
        <a href="#" target="_blank" style="margin: 0">
            <span>Homer</span>
        </a>
    </b>
    <b> Version </b> {{ appVersion }}
   <span>{{ currentYear }}</span>
</div>

