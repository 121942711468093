<div class="card card-outline card-primary">
    <div class="card-header text-center">
        <a [routerLink]="['/']" class="h1"><b>Avatar Sports</b></a>
    </div>
    <div class="card-body">
        <p class="login-box-msg">Trocar a senha</p>

        <form [formGroup]="loginForm" (ngSubmit)="alterarSenha()">
            <div class="input-group mb-3">
                <input
                    formControlName="senha_atual"
                    type="password"
                    class="form-control"
                    placeholder="Senha Atual"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
            </div>
            <div class="input-group mb-3">
                <input
                    formControlName="senha1"
                    type="password"
                    class="form-control"
                    placeholder="Nova senha"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
            </div>

            <div class="input-group mb-3">
                <input
                    formControlName="senha2"
                    type="password"
                    class="form-control"
                    placeholder="Repetir a senha"
                />
                <div class="input-group-append">
                    <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                    </div>
                </div>
            </div>
            <div class="row">
                <!--div class="col-7">
                    <div class="icheck-primary">
                        <input type="checkbox" id="remember" />
                        <label for="remember"> Remember Me </label>
                    </div>
                </div>-->
                <!-- /.col -->
                <div class="col-5">
                    <pf-button
                        [type]="'submit'"
                        [block]="true"
                        [loading]="isAuthLoading"
                        [disabled]="isFacebookLoading || isGoogleLoading"
                    >
                        Alterar
                    </pf-button>
                </div>
                <!-- /.col -->
            </div>
        </form>

        

        <!--<p class="mb-0">
            <a [routerLink]="['/register']" class="text-center">
                Register a new membership
            </a>
        </p>-->
    </div>
    <!-- /.login-card-body -->
</div>
