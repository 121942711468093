import {
    Component,
    OnInit,
    OnDestroy,
    Renderer2,
    HostBinding
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
import { Router } from '@angular/router';

import * as CryptoJS from 'crypto-js';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
    @HostBinding('class') class = 'login-box';
    public loginForm: UntypedFormGroup;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;

    private key = CryptoJS.enc.Utf8.parse("46pzR1D9rnyyX6ifJhvAnIANYIeHsfWf");
    private iv = CryptoJS.enc.Utf8.parse("1tRLHFObk5orY9GD");

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
        this.loginForm = new UntypedFormGroup({
            email: new UntypedFormControl(null, Validators.required),
            password: new UntypedFormControl(null, Validators.required)
        });
    }

    encryptUsingAES256(text): any {
        var encrypted = CryptoJS.AES.encrypt(text, this.key,
            {
                mode: CryptoJS.mode.CBC,
                iv: this.iv
            });
        return encrypted.toString();
    }

    decryptUsingAES256(decString) {
        var decrypted = CryptoJS.AES.decrypt(decString, this.key, {
            keySize: 128 / 8,
            iv: this.iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }


    loginByAuth() {
        if (this.loginForm.valid) {
            this.isAuthLoading = true;
            // console.log(this.loginForm.value)
            var hash = CryptoJS.SHA256(this.loginForm.value.password).toString(CryptoJS.enc.Hex);
            //console.log(hash)
            const payload = {
                email: this.encryptUsingAES256(this.loginForm.value.email),
                senha: this.encryptUsingAES256(hash)
            }
            this.appService.loginByAuth(payload)
                .subscribe(res => {

                    if (res.status === 'Sucesso') {
                        var data = res.data[0];
                        var usuario = this.decryptUsingAES256(data.usuario);
                        var forcar_troca = this.decryptUsingAES256(data.forcar_troca);
                        var perfil = this.decryptUsingAES256(data.perfil);


                       
                        if (forcar_troca == 1) {

                            this.router.navigateByUrl('/forcar-troca-senha/' + encodeURIComponent(data.id_usuario));
                        }
                        else {
                            localStorage.setItem('token', data.token);
                            localStorage.setItem('usuario', usuario);
                            localStorage.setItem('perfil', perfil);
                            localStorage.setItem('idUsuario', data.id_usuario);
                            this.toastr.success('Login sucesso');
                            this.router.navigate(['/treinador/lst-treinador']);
                        }

                    }
                    else {
                        this.toastr.error(res.msg);
                    }
                    //const token = await Gatekeeper.loginByAuth(email, password);
                    //localStorage.setItem('token', token);
                    //await this.getProfile();
                    //this.router.navigate(['/']);
                    //this.toastr.success('Login success');
                });
            this.isAuthLoading = false;
        } else {
            this.toastr.error('Form is not valid!');
        }
    }

    async loginByGoogle() {
        this.isGoogleLoading = true;
        await this.appService.loginByGoogle();
        this.isGoogleLoading = false;
    }

    async loginByFacebook() {
        this.isFacebookLoading = true;
        await this.appService.loginByFacebook();
        this.isFacebookLoading = false;
    }

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}
