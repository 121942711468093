import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TelaService {

  constructor(private http: HttpClient,) { }

  apiUrl = environment.baseUrl;
  httpOptions = {
    headers: new HttpHeaders(
      {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': 'http://localhost:4200'
      }
    )
  };

  getPerfilTela(id_usuario: string, token: string) {
    const url = `${this.apiUrl}/telaPerfil?idUsuario=${id_usuario}&token=${token}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(``)),
      catchError(this.handleError<any>(``))
    );
  }

  getTelaSel(id_usuario: string, token: string) {
    const url = `${this.apiUrl}/telaSel?idUsuario=${id_usuario}&token=${token}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(``)),
      catchError(this.handleError<any>(``))
    );
  }



  getTelas(id_usuario: string, token: string, pagina:string, limite:string): Observable<any> {
    const url = `${this.apiUrl}/telaRel?idUsuario=${id_usuario}&token=${token}&pagina=${pagina}&limite=${limite}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(``)),
      catchError(this.handleError<any>(``))
    );
  }

  getTelasFilho(id_usuario: string, token: string, pagina:number, limite:number, id:string): Observable<any> {
    const url = `${this.apiUrl}//Acesso/TelaFilhoRel?idUsuario=${id_usuario}&token=${token}&pagina=${pagina}&limite=${limite}&id=${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(``)),
      catchError(this.handleError<any>(``))
    );
  }




  addTela(payload) {
    return this.http.post<any>(`${this.apiUrl}/tela`, payload, this.httpOptions).pipe(
      tap(_ => console.log("Cadastrado")),
      catchError(this.handleError("addCategoria", []))
    );
  }

  addTelaFilho(payload) {
    return this.http.post<any>(`${this.apiUrl}/Acesso/TelaFilho`, payload, this.httpOptions).pipe(
      tap(_ => console.log("Cadastrado")),
      catchError(this.handleError("addCategoria", []))
    );
  }

  getTotalTela(id_usuario: string, token: string) {
    const url = `${this.apiUrl}/telaTotal?idUsuario=${id_usuario}&token=${token}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(``)),
      catchError(this.handleError<any>(``))
    );
  }

  getTotalTelaFilho(id_usuario: string, token: string, id: string) {
    const url = `${this.apiUrl}/Acesso/TelaFilhoTotal?idUsuario=${id_usuario}&token=${token}&id=${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(``)),
      catchError(this.handleError<any>(``))
    );
  }

  deleteTela(id_usuario: string, token: string, id: string) {
    const url = `${this.apiUrl}/apagarTela?idUsuario=${id_usuario}&token=${token}&idTela=${id}`;
    return this.http.delete<any>(url).pipe(
      tap(_ => console.log(`fetched todo id=${id}`)),
      catchError(this.handleError<any>(`getTodo id=${id}`))
    );
  }

  deleteTelaFilho(id_usuario: string, token: string, id: string, id_tela_filho:string) {
    const url = `${this.apiUrl}/Acesso/TelaFilho?idUsuario=${id_usuario}&token=${token}&idTela=${id}&idTelaFilho=${id_tela_filho}`;
    return this.http.delete<any>(url).pipe(
      tap(_ => console.log(`fetched todo id=${id}`)),
      catchError(this.handleError<any>(`getTodo id=${id}`))
    );
  }




  getTela(id: string, id_usuario: string, token: string): Observable<any> {
    const url = `${this.apiUrl}/tela?idUsuario=${id_usuario}&token=${token}&idTela=${id}`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched todo id=${id}`)),
      catchError(this.handleError<any>(`getTodo id=${id}`))
    );
  }

  updateTela(tela): Observable<any> {
    const url = `${this.apiUrl}/tela`;
    return this.http.put(url, tela, this.httpOptions).pipe(
      tap(_ => console.log(`updated todo id=${tela.id_tela}`)),
      catchError(this.handleError<any>("updateTodo"))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      //console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(error as T);
    };
  }
}
